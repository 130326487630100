<template>
	<h1>Removal Requests</h1>
	<p>
		Arthur is a non-commercial, advertising-free platform. Every artwork you see was uploaded by one of our
		users. Just like a museum, our goal is to amplify the artists' work, not to profit from it.
	</p>
	<p>
		If you wish us to remove your work regardless however, we will promptly do so.
	</p>
	<br />
	<ul>
		<li class="bump-b">
			<b>Remove artist profile: </b>
			<a
				href="mailto:remove.artist@arthur.io?subject=Please Remove Artist&body=Dear copyright agent,%0D%0A%0D%0AI understand that Arthur is a non-commercial platform meant to help artists show their work to world, however I do not wish to participate and would like you to remove my artist profile at the following link:%0D%0A[insert link to artist profile]%0D%0A%0D%0AThank you,%0D%0A[insert name]"
				>remove.artist@arthur.io</a
			>
		</li>
		<li class="bump-b">
			<b>Remove single artwork: </b>
			<a
				href="mailto:remove.artwork@arthur.io?subject=Please Remove Artwork&body=Dear copyright agent,%0D%0A%0D%0AI would like you to remove the following artwork:%0D%0A[insert link to artist profile]%0D%0A%0D%0AFor the following reason:%0D%0A[insert reason]%0D%0A%0D%0AThank you,%0D%0A[insert name]"
				>remove.artwork@arthur.io</a
			>
		</li>
		<li class="bump-b">
			<b>Anything else: </b>
			<a href="mailto:copyright@arthur.io?subject=Copyright Issue">copyright@arthur.io</a>
		</li>
	</ul>
</template>

<script>
import { injectMetaData } from '@/use/MetaData'

export default {
	name: 'LegalRemoval',
	setup() {
		injectMetaData({
			title: 'Arthur.io • Removal Requests',
		})
	},
}
</script>
